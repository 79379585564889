import { SvgIconProps } from '@material-ui/core/SvgIcon';
import {
  AllInbox,
  Apps,
  DeveloperBoard,
  HowToVote,
  InsertChartOutlined,
  MenuOpen,
  Store,
  SupervisedUserCircle,
  TabUnselected,
  VideoLabel,
  ViewCarousel,
} from '@material-ui/icons';
// import Assignment from '@material-ui/icons/Assignment';
// import DashboardIcon from '@material-ui/icons/Dashboard';
import Group from '@material-ui/icons/Group';
import React from 'react';
import { AreaManagement } from './AreaManagement/AreaManagement';
import { AreaUsers } from './AreaManagement/AreaUsers';
import { Permissions } from './Common/Stores/ApiStore';
import { CompartmentDetails } from './CompartmentManagement/CompartmentDetails';
import { CompartmentManagement } from './CompartmentManagement/CompartmentManagement';
import { LabelManagement } from './LabelManagement/LabelManagement';
import { LinkProfileManagement } from './LinkProfileManagement/LinkProfileManagement';
import { OperationGroupDetails } from './OperationGroups/OperationGroupDetails';
import { OperationGroups } from './OperationGroups/OperationGroups';
import { PickingAreas } from './Picking/PickingAreas';
import { PickingSides } from './Picking/PickingSides';
import { PresetManagement } from './PresetManagement/PresetManagement';
import { RoleManagement } from './RoleManagement/RoleManagement';
import { RoleUsers } from './RoleManagement/RoleUsers';
import { SystemStatus } from './SystemStatus/SystemStatus';
import { TemplateManagement } from './TemplateManagement/TemplateManagement';
// import { TaskManagement } from './TaskManagement/TaskManagement';
import { UserManagement } from './UserManagement/UserManagement';
import { UserMappings } from './UserManagement/UserMappings';
import { YourProfile } from './UserManagement/YourProfile';
import { NoPermissions } from './Welcome/Components/NoPermissions';
// import { Dashboard } from './Welcome/Components/Dashboard';

export type TitleChangeFn = (customText: string) => void;

interface TitleChangeable {
  title: string;
  change: TitleChangeFn;
}

type TitleFn = () => TitleChangeable;

interface MenuEntry {
  link: string;
  text: string;
  icon: React.ComponentType<SvgIconProps>;
}

export type TitleProps = { title: string };

export interface RouteShortDefinition {
  path: string;
  menu?: MenuEntry;
  component: (props: Record<string, unknown> & TitleProps) => JSX.Element;
  title: string;
  changeableTitle?: TitleFn;
  exact: boolean;
  permission?: string;
  children?: RouteShortDefinition[];
}

export const Routes: RouteShortDefinition[] = [
  // {
  //   path: '/dashboard',
  //   component: Dashboard,
  //   title: 'Dashboard',
  //   exact: true,
  //   icon: DashboardIcon,
  // },
  {
    path: '/compartments/:compartmentidentifier',
    component: CompartmentDetails,
    title: 'Compartment Details',
    exact: true,
    permission: Permissions.SHELFLABELING_READ,
  },
  {
    path: '/compartments/',
    menu: {
      link: '/compartments',
      text: 'Compartments',
      icon: AllInbox,
    },
    component: CompartmentManagement,
    title: 'Compartments',
    exact: false,
    permission: Permissions.SHELFLABELING_READ,
  },
  {
    path: '/labels/',
    menu: {
      link: '/labels',
      text: 'Labels',
      icon: VideoLabel,
    },
    component: LabelManagement,
    title: 'Labels',
    exact: false,
    permission: Permissions.SHELFLABELING_READ,
  },
  {
    path: '/templates/',
    menu: {
      link: '/templates',
      text: 'Templates',
      icon: TabUnselected,
    },
    component: TemplateManagement,
    title: 'Templates',
    exact: false,
    permission: Permissions.TEMPLATES_READ,
  },
  {
    path: '/areas/:nodeId/users/',
    component: AreaUsers,
    title: 'Users of Area',
    exact: true,
    permission: Permissions.AREAS_READ,
  },
  {
    path: '/presets/',
    menu: {
      link: '/presets',
      text: 'Presets',
      icon: MenuOpen,
    },
    component: PresetManagement,
    title: 'Presets',
    exact: false,
    permission: Permissions.PRESETS_WRITE,
  },
  {
    path: '/linkprofiles/',
    menu: {
      link: '/linkprofiles',
      text: 'Link Profiles',
      icon: ViewCarousel,
    },
    component: LinkProfileManagement,
    title: 'Link Profiles',
    exact: false,
    permission: Permissions.LINK_PROFILES_WRITE,
  },
  {
    path: '/areas/:id?',
    menu: {
      link: '/areas',
      text: 'Areas',
      icon: Apps,
    },
    component: AreaManagement,
    title: 'Areas',
    exact: false,
    permission: Permissions.AREAS_READ,
  },
  {
    path: '/picking/sides/',
    menu: {
      link: '/picking/sides',
      text: 'Picking: Sides',
      icon: HowToVote,
    },
    component: PickingSides,
    title: 'Picking: Sides',
    exact: false,
    permission: Permissions.PICKING_AREAS_READ,
  },
  {
    path: '/picking/areas/',
    menu: {
      link: '/picking/areas',
      text: 'Picking: Areas',
      icon: Store,
    },
    component: PickingAreas,
    title: 'Picking: Areas',
    exact: false,
    permission: Permissions.PICKING_AREAS_READ,
  },
  {
    path: '/operationgroups/:operationgroupid',
    component: OperationGroupDetails,
    title: 'Operation-Group',
    exact: true,
    permission: Permissions.OPERATION_GROUPS_READ,
  },
  {
    path: '/operationgroups/',
    menu: {
      link: '/operationgroups',
      text: 'Operation-Groups',
      icon: DeveloperBoard,
    },
    component: OperationGroups,
    title: 'Operations-Groups',
    exact: false,
    permission: Permissions.OPERATION_GROUPS_READ,
  },
  {
    path: '/roles/:roleId/users/',
    component: RoleUsers,
    title: 'Users with Role',
    exact: true,
    permission: Permissions.ROLES_READ,
  },
  {
    path: '/roles/',
    menu: {
      link: '/roles',
      text: 'Roles',
      icon: SupervisedUserCircle,
    },
    component: RoleManagement,
    title: 'Roles',
    exact: true,
    permission: Permissions.ROLES_READ,
  },
  // {
  //   path: '/tasks/',
  //   menuLink: '/tasks',
  //   component: TaskManagement,
  //   title: () => 'Tasks',
  //   exact: false,
  //   icon: Assignment,
  // },
  {
    path: '/users/:id?',
    menu: {
      link: '/users',
      text: 'Users',
      icon: Group,
    },
    component: UserManagement,
    title: 'Users',
    exact: false,
    permission: Permissions.USERS_READ,
  },
  {
    path: '/mappings/:userid',
    component: UserMappings,
    title: 'User-Roles',
    exact: false,
    permission: Permissions.MAPPINGS_READ,
  },
  {
    path: '/system/',
    menu: {
      link: '/system',
      text: 'System-Status',
      icon: InsertChartOutlined,
    },
    component: SystemStatus,
    title: 'System-Status',
    exact: false,
    permission: Permissions.SYSTEMSTATUS_READ,
  },
  {
    path: '/me/',
    component: YourProfile,
    exact: false,
    title: 'YourProfile',
  },
  {
    path: '/',
    component: NoPermissions,
    title: 'No Permissions',
    exact: true,
  },
];
