import {
  CompartmentSelectionStrategy,
  CompartmentSelectorConfiguration,
  LinkProfile,
  TemplateGroups,
} from '@ekkogmbh/apisdk';
import { action, observable } from 'mobx';

export interface LinkProfileState {
  name: string;
  technology: string;
  coordinate: string;
  compartmentSelector: CompartmentSelectionStrategy;
  compartmentSelectorConfiguration: CompartmentSelectorConfiguration;
  templateGroups: TemplateGroups;
  autoTemplateGroup?: string;
  allFilled: boolean;
}

export class LinkProfileStore {
  @observable
  public editableLinkProfile?: LinkProfile;

  @observable
  public state: LinkProfileState = {
    name: '',
    technology: '',
    coordinate: '',
    compartmentSelector: CompartmentSelectionStrategy.COORDINATE,
    compartmentSelectorConfiguration: {},
    templateGroups: {},
    autoTemplateGroup: undefined,
    allFilled: false,
  };

  @action
  public setState = (newState: Partial<LinkProfileState>, setAllFilled: boolean = true): void => {
    this.state = {
      ...this.state,
      ...newState,
    };

    if (setAllFilled) {
      const allFilled =
        this.state.name !== '' &&
        this.state.technology !== '' &&
        this.state.coordinate !== '' &&
        this.state.compartmentSelector !== undefined &&
        this.state.templateGroups !== undefined;

      this.state = {
        ...this.state,
        allFilled,
      };
    }
  };

  @action
  public resetStore = (): void => {
    this.setState({
      name: '',
      technology: '',
      coordinate: '',
      compartmentSelector: CompartmentSelectionStrategy.COORDINATE,
      compartmentSelectorConfiguration: {},
      templateGroups: {},
      autoTemplateGroup: '',
    });

    this.editableLinkProfile = undefined;
  };

  @action
  public setEditableLinkProfile = (linkProfile?: LinkProfile): void => {
    this.editableLinkProfile = linkProfile;

    if (linkProfile) {
      const { name, technology, coordinate, compartmentSelector, autoTemplateGroup } = linkProfile;
      this.setState({
        name,
        technology,
        coordinate,
        autoTemplateGroup: autoTemplateGroup ?? undefined,
        compartmentSelector: compartmentSelector.type,
        compartmentSelectorConfiguration: compartmentSelector.configuration,
      });
    }
  };
}
