import { Grid, Typography, withStyles, WithStyles } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import { InjectedNotistackProps, withSnackbar } from 'notistack';
import React from 'react';
import { spacer } from '../../Common/Components/Forms/Spacer';
import { FormStyles } from '../../Common/Styles/FormStyles';
import { TemplateStore } from '../Stores/TemplateStore';
import { TemplatePreview } from './TemplatePreview';

const styles = FormStyles;

interface TemplateCreateOverviewProps extends WithStyles<typeof styles>, InjectedNotistackProps {
  templateStore?: TemplateStore;
}

@inject('templateStore')
@observer
class TemplateCreateOverviewComponent extends React.Component<TemplateCreateOverviewProps> {
  public render() {
    const { templateStore } = this.props;
    const {
      state: { name, coordinate, metadata, type },
      templateFile,
      rendererResult,
    } = templateStore!;

    const overlineStyle = { fontWeight: 700 };

    const parsedFields = rendererResult!.fields;
    const parsedKeys = Object.keys(parsedFields);
    const combinedKeys = parsedKeys.concat(metadata.keys.filter((key: unknown) => typeof key === 'string'));

    return (
      <Grid container spacing={2} alignItems={'stretch'}>
        <Grid item xs={6}>
          <Typography variant="overline" color={'primary'} style={overlineStyle}>
            Name
          </Typography>
          <Typography variant="h6" gutterBottom>
            {name}
          </Typography>
          <Typography variant="overline" color={'primary'} style={overlineStyle}>
            Coordinate
          </Typography>
          <Typography variant="h6" gutterBottom>
            {coordinate}
          </Typography>
          <Typography variant="overline" color={'primary'} style={overlineStyle}>
            Type
          </Typography>
          <Typography variant="h6" gutterBottom>
            {type}
          </Typography>
          <Typography variant="overline" color={'primary'} style={overlineStyle}>
            Keys
          </Typography>
          <Grid container item>
            {combinedKeys.map((key: string) => (
              <Grid item xs={12} key={`template-key-${key}`}>
                <Typography variant="h6" display={'inline'} gutterBottom>
                  {key}
                </Typography>
                {parsedKeys.includes(key) && (
                  <Typography color={'primary'} display={'inline'} style={{ margin: '4px' }}>
                    ({parsedFields[key]})
                  </Typography>
                )}
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid container item xs={6}>
          {templateFile && (
            <Grid item xs={12}>
              <Typography variant="overline" color={'primary'} style={overlineStyle}>
                Filename
              </Typography>
              <Typography variant="h6" gutterBottom>
                {templateFile!.name}
              </Typography>
              <Typography variant="overline" color={'primary'} style={overlineStyle}>
                Filesize
              </Typography>
              <Typography variant="h6" gutterBottom>
                {Number(templateFile!.size / 1024).toFixed(2)} kB
              </Typography>
            </Grid>
          )}
          <Grid item xs={'auto'} zeroMinWidth>
            <Typography variant="overline" color={'primary'} style={overlineStyle}>
              Render Preview
            </Typography>
            <TemplatePreview rendererResult={rendererResult!} />
          </Grid>
        </Grid>

        {spacer(3)}
      </Grid>
    );
  }
}

const SnackbarWrapped = withSnackbar<TemplateCreateOverviewProps>(TemplateCreateOverviewComponent);
const StyleWrapped = withStyles(styles)(SnackbarWrapped);

export const TemplateCreateOverview = StyleWrapped;
