import { EventEmitter } from 'eventemitter3';
import { action, observable } from 'mobx';
import React from 'react';
import { scrollTop } from '../Helper/ScrollTop';

export class NavigationStore extends EventEmitter {
  @observable
  public open: boolean = false;

  @observable
  public clientHeight: number = 0;

  @observable
  private scrollRef: React.RefObject<HTMLDivElement> | null = null;

  public getScrollComponent(): React.RefObject<HTMLDivElement> | null {
    return this.scrollRef;
  }

  @action
  public drawerOpen(): void {
    this.open = true;
    this.emit('drawer-state-change');
  }

  @action
  public drawerClose(): void {
    this.open = false;
    this.emit('drawer-state-change');
  }

  public setScrollComponent(ref: React.RefObject<HTMLDivElement>): void {
    this.scrollRef = ref;
    this.emit('scroll-component-set');
  }

  public unsetScrollComponent(): void {
    this.scrollRef = null;
    this.emit('scroll-component-unset');
  }

  @action
  public scrollTop(duration: number = 1000, easing: string = 'easeOutQuart', callback?: () => void): void {
    if (!this.scrollRef) {
      return;
    }

    const element = this.scrollRef.current;

    if (element !== null) {
      scrollTop(element, duration, easing, callback);
    }

    return;
  }
}
