import {
  EslManagerPrivateRoute,
  HttpMethod,
  Template,
  TemplateSavePayload,
  Pagination,
  PaginationResponse,
  RendererResult,
  RendererPayload,
  TemplateWithData,
  ApplicableRenderersPayload,
  ApplicableRenderersResult,
} from '@ekkogmbh/apisdk';
import { Grid, Paper, withStyles, WithStyles } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { MaterialDatatableColumnDef } from 'material-datatable';
import { inject, observer } from 'mobx-react';
import { InjectedNotistackProps, withSnackbar } from 'notistack';
import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ConfirmationDialog } from 'src/Common/Components/ConfirmationDialog';
import { DataTable, DataTableFilterFields, DataTableSortFieldMap } from 'src/Common/Components/DataTable';
import { createBlobDownload } from 'src/Common/Helper/BlobDownload';
import { request } from 'src/Common/Helper/FetchHandler';
import { CancelableFetchPromises, cancelFetchPromises } from 'src/Common/Helper/PromiseHelper';

import { ApiStore, Permissions } from 'src/Common/Stores/ApiStore';
import { SuccessHandlerStatusMessages } from 'src/Common/Helper/ResponseHandler';
import { NavigationStore } from 'src/Common/Stores/NavigationStore';
import { PaginationStore } from 'src/Common/Stores/PaginationStore';
import { SearchContentStore } from 'src/Common/Stores/SearchContentStore';
import { ContentPanelDefinition, ContentPanels } from '../../Common/Components/ContentPanels';
import { TemplateStore } from '../Stores/TemplateStore';
import { TemplateManagementStyles } from '../Styles/TemplateManagementStyles';
import { materialDatatableColumnDefinitions } from './TemplateManagementDatatableColumnDefinitions';
import { TemplatePanel, TemplatePanelProps } from './TemplatePanel';
import { TemplatePreviewDialog } from './TemplatePreviewDialog';
import { ConfigStore } from 'src/Common/Stores/ConfigStore';

const styles = TemplateManagementStyles;

const stores = ['api', 'templateStore', 'paginationStore', 'searchContentStore', 'navigationStore', 'configStore'];

interface TemplateManagementContentActions {
  updateTemplate?: (template: TemplateWithData) => void;
}

export interface TemplateManagementContentActionHandlers {
  preview: (template: TemplateWithData) => void;
  download: (template: TemplateWithData) => void;
  edit: (template: TemplateWithData) => void;
  delete: (template: Template) => void;
}

export interface TemplateManagementContentState {
  previewTemplate?: TemplateWithData;
  deletableTemplate?: Template;
  deleteDialogOpen: boolean;
  previewDialogOpen: boolean;
}

export interface TemplateManagementContentStores {
  api: ApiStore;
  templateStore: TemplateStore;
  paginationStore: PaginationStore;
  searchContentStore: SearchContentStore;
  navigationStore: NavigationStore;
  configStore: ConfigStore;
}

export interface TemplateManagementContentProps
  extends WithStyles<typeof styles>,
    RouteComponentProps,
    InjectedNotistackProps {}

export type TemplateManagementContentPropsWithStores = TemplateManagementContentProps & TemplateManagementContentStores;

@inject(...stores)
@observer
class TemplateManagementContentComponent extends Component<
  TemplateManagementContentProps,
  TemplateManagementContentState
> {
  private readonly filterFields: DataTableFilterFields<Template> = ['name', 'type'];

  private readonly sortFieldMap: DataTableSortFieldMap<Template> = {
    name: 'T.name',
    type: 'T.type',
  };

  private fetchPromises: CancelableFetchPromises = {};

  private readonly actions: TemplateManagementContentActions = {};

  private readonly successStatusCodes: SuccessHandlerStatusMessages = {
    201: 'Template created.',
    204: 'Template deleted.',
  };

  get stores(): TemplateManagementContentStores {
    return this.props as TemplateManagementContentPropsWithStores;
  }

  public state: TemplateManagementContentState = {
    previewDialogOpen: false,
    deleteDialogOpen: false,
    previewTemplate: undefined,
    deletableTemplate: undefined,
  };

  public componentWillUnmount(): void {
    cancelFetchPromises(this.fetchPromises);
  }

  public fetchTemplates = async (pagination: Pagination): Promise<PaginationResponse<TemplateWithData>> => {
    const { api } = this.stores;
    const { enqueueSnackbar } = this.props;

    return await request<PaginationResponse<TemplateWithData>>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.getTemplates(pagination),
      EslManagerPrivateRoute.TEMPLATE,
      HttpMethod.GET,
    );
  };

  public fetchRendererResult = async (payload: RendererPayload): Promise<RendererResult> => {
    const { api, templateStore } = this.stores;
    const { enqueueSnackbar } = this.props;

    templateStore.loading = true;

    return await request<RendererResult>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.renderTemplate(payload),
      EslManagerPrivateRoute.RENDERER,
      HttpMethod.POST,
      undefined,
      () => {
        templateStore.loading = false;
      },
      () => {
        templateStore.loading = false;
      },
    );
  };

  public getApplicableRenderers = async (payload: ApplicableRenderersPayload): Promise<ApplicableRenderersResult> => {
    const { api, templateStore } = this.stores;
    const { enqueueSnackbar } = this.props;

    templateStore.loading = true;

    return await request<ApplicableRenderersResult>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.getApplicableRenderers(payload),
      EslManagerPrivateRoute.APPLICABLE_RENDERERS,
      HttpMethod.POST,
      undefined,
      () => {
        templateStore.loading = false;
      },
      () => {
        templateStore.loading = false;
      },
    );
  };

  public saveTemplate = async (
    coordinate: string,
    name: string,
    template: TemplateSavePayload,
  ): Promise<TemplateWithData> => {
    const { api, searchContentStore } = this.stores;
    const { enqueueSnackbar } = this.props;

    const successCallback = () => {
      searchContentStore.emitRefresh();
    };

    return await request<TemplateWithData>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.saveTemplate(coordinate, name, template),
      EslManagerPrivateRoute.SAVE_TEMPLATE,
      HttpMethod.PUT,
      this.successStatusCodes,
      successCallback,
    );
  };

  public onEdit = async (template: TemplateWithData): Promise<void> => {
    const { navigationStore } = this.stores;
    const { updateTemplate } = this.actions;

    if (updateTemplate) {
      updateTemplate(template);
      navigationStore!.scrollTop();
    }
  };

  public onDownload = async (template: TemplateWithData): Promise<void> => {
    const { enqueueSnackbar } = this.props;

    try {
      const decodedData = Buffer.from(template.data, 'base64').toString('utf8');
      const blob = new Blob([decodedData], { type: 'text/plain' });
      const filename = `${template.name}.xsl`;
      createBlobDownload(blob, filename);
    } catch (e) {
      enqueueSnackbar(e.toString(), { variant: 'error' });
    }
  };

  public onPreview = async (template: TemplateWithData): Promise<void> => {
    this.setState({
      previewDialogOpen: true,
      previewTemplate: template,
    });
  };

  public onPreviewDismiss = () => {
    this.setState({ previewDialogOpen: false, previewTemplate: undefined });
  };

  public openDeleteDialog = async (template: Template): Promise<void> => {
    this.setState({
      deletableTemplate: template,
      deleteDialogOpen: true,
    });
  };

  public onDeleteDismiss = () => {
    this.setState({
      deletableTemplate: undefined,
      deleteDialogOpen: false,
    });
  };

  public onDeleteOk = async () => {
    const { deletableTemplate } = this.state;

    if (!deletableTemplate || !deletableTemplate.id) {
      return;
    }

    this.deleteTemplate(deletableTemplate);
    this.onDeleteDismiss();
  };

  public deleteTemplate = async (template: Template): Promise<void> => {
    const { api, searchContentStore } = this.stores;
    const { enqueueSnackbar } = this.props;

    const successCallback = () => {
      searchContentStore.emitRefresh();
    };

    return await request<void>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.deleteTemplate(template),
      EslManagerPrivateRoute.TEMPLATE,
      HttpMethod.DELETE,
      this.successStatusCodes,
      successCallback,
    );
  };

  public createContentPanels = (): ContentPanelDefinition[] => {
    const { templateStore, configStore } = this.stores;
    const { coordinateFieldName } = configStore.config;
    const { saveTemplate, fetchRendererResult, getApplicableRenderers } = this;

    const closeCallback = () => {
      templateStore.resetStore();
    };

    const addTemplatePanelDefinition: ContentPanelDefinition<TemplatePanelProps> = {
      name: 'Add',
      icon: Add,
      isHidden: false,
      panelComponent: TemplatePanel,
      panelProps: {
        saveHandler: saveTemplate,
        closeCallback,
        fetchRendererResult,
        getApplicableRenderers,
      },
      permission: Permissions.TEMPLATES_WRITE,
      toggleOffCallback: closeCallback,
      expandHandler: (expandCallback: () => void) => {
        const { updateTemplate } = this.actions;
        if (updateTemplate === undefined) {
          this.actions.updateTemplate = async (template: TemplateWithData) => {
            expandCallback();

            const rendererResult = await this.fetchRendererResult({
              data: template.data,
              type: template.type,
              fields: {},
              autoFill: true,
              render: true,
            });

            const keys = Object.keys(rendererResult.fields);
            if (keys.includes(coordinateFieldName)) {
              const newFields = rendererResult.fields;
              delete newFields[coordinateFieldName];
              rendererResult.fields = newFields;
              templateStore.reservedField = true;
            }

            templateStore.setEditableTemplate(template, rendererResult);
          };
        }
      },
    };

    return [addTemplatePanelDefinition];
  };

  public render() {
    const { classes } = this.props;
    const { previewTemplate, deletableTemplate, deleteDialogOpen, previewDialogOpen } = this.state;

    const contentPanels = this.createContentPanels();

    const columnDefinition: MaterialDatatableColumnDef[] = materialDatatableColumnDefinitions.map((defFn) =>
      defFn(this.state, this.props as TemplateManagementContentPropsWithStores, {
        preview: this.onPreview,
        delete: this.openDeleteDialog,
        download: this.onDownload,
        edit: this.onEdit,
      }),
    );

    const deleteDialogText =
      deleteDialogOpen && deletableTemplate ? (
        <React.Fragment>
          <div>
            name: <span className={classes.boldFont}>{deletableTemplate.name}</span>
          </div>
          <div>
            type: <span className={classes.boldFont}>{deletableTemplate.type}</span>
          </div>
        </React.Fragment>
      ) : (
        ''
      );

    return (
      <Grid item xs={12}>
        <ContentPanels panels={contentPanels} />

        {deleteDialogOpen && (
          <ConfirmationDialog
            maxWidth={'sm'}
            fullWidth={true}
            centered={true}
            open={deleteDialogOpen}
            title={'Delete Template'}
            text={deleteDialogText}
            onClose={this.onDeleteDismiss}
            onConfirm={this.onDeleteOk}
          />
        )}

        {previewTemplate && (
          <TemplatePreviewDialog
            template={previewTemplate}
            fetchRendererResult={this.fetchRendererResult}
            onClose={this.onPreviewDismiss}
            dialogProps={{
              open: previewDialogOpen,
              fullWidth: true,
              maxWidth: 'lg',
            }}
          />
        )}

        <Paper>
          <DataTable
            fetchItems={this.fetchTemplates}
            columns={columnDefinition}
            filterFields={this.filterFields}
            sortFieldMap={this.sortFieldMap}
            options={{
              sortColumnIndex: 0,
              sortColumnDirection: 'desc',
            }}
          />
        </Paper>
      </Grid>
    );
  }
}

const RouterWrapped = withRouter<TemplateManagementContentProps, typeof TemplateManagementContentComponent>(
  TemplateManagementContentComponent,
);
const SnackbarWrapped = withSnackbar<Omit<TemplateManagementContentProps, keyof RouteComponentProps>>(RouterWrapped);
const StyleWrapped = withStyles(styles)(SnackbarWrapped);

export const TemplateManagementContent = StyleWrapped;
