import { RendererResult, Template, TemplateType } from '@ekkogmbh/apisdk';
import { action, observable } from 'mobx';

export interface TemplateState {
  name: string;
  type: TemplateType;
  data: string;
  metadata: Record<string, any>; // eslint-disable-line @typescript-eslint/no-explicit-any
  coordinate: string;
  allFilled: boolean;
}

export class TemplateStore {
  // serves as cache for resetting the panel in edit mode
  private editableTemplateRendererResult?: RendererResult;

  @observable
  public templateFile?: File;

  @observable
  public rendererResult?: RendererResult;

  @observable
  public reservedField: boolean = false;

  @observable
  public editableTemplate?: Template;

  @observable
  public state: TemplateState = {
    name: '',
    type: TemplateType.APACHE_FOP,
    data: '',
    metadata: { keys: [] },
    coordinate: '',
    allFilled: false,
  };

  @observable
  public loading: boolean = false;

  @observable
  public changed: boolean = false;

  @action
  public setState = (newState: Partial<TemplateState>, setAllFilled: boolean = true): void => {
    this.state = {
      ...this.state,
      ...newState,
    };

    if (setAllFilled) {
      const allFilled = (this.state.name !== '' && this.state.coordinate !== '') || this.editableTemplate !== undefined;

      this.state = {
        ...this.state,
        allFilled,
      };
    }
  };

  @action
  public resetState = (): void => {
    this.setState(
      {
        name: '',
        data: '',
        type: TemplateType.APACHE_FOP,
        metadata: { keys: [] },
      },
      true,
    );
  };

  @action
  public resetStore = (editMode: boolean = false): void => {
    this.removeFile();

    if (editMode && this.editableTemplate && this.editableTemplateRendererResult) {
      this.setEditableTemplate(this.editableTemplate, this.editableTemplateRendererResult);
    } else {
      this.editableTemplate = undefined;
      this.editableTemplateRendererResult = undefined;
      this.rendererResult = undefined;
      this.resetState();
    }

    this.changed = false;
    this.loading = false;
  };

  @action
  public setFile = (file: File): void => {
    this.templateFile = file;
    this.changed = true;

    if (!this.editableTemplate) {
      const basename = file.name.substring(0, file.name.lastIndexOf('.')) || file.name;
      this.setState({ name: basename });
    }
  };

  @action
  public setRenderResult = (result: RendererResult): void => {
    this.rendererResult = result;
  };

  @action
  public setEditableTemplate = (template: Template, rendererResult: RendererResult): void => {
    this.editableTemplate = template;
    this.editableTemplateRendererResult = rendererResult;
    this.setRenderResult(rendererResult);

    const parsedKeys = Object.keys(rendererResult.fields);
    const metadataKeys = template.metadata.keys.filter((key: string) => !parsedKeys.includes(key));

    this.setState(
      {
        name: template.name,
        data: template.data,
        type: template.type,
        metadata: { keys: metadataKeys },
        coordinate: template.coordinate,
      },
      true,
    );
  };

  @action
  public removeFile = (): void => {
    this.templateFile = undefined;
    this.loading = false;
    this.changed = true;
    this.reservedField = false;
  };
}
