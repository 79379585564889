import { Divider, Drawer, IconButton, List, ListItem, ListItemText, withStyles, WithStyles } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography/Typography';
import { HelpOutline } from '@material-ui/icons';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import MenuIcon from '@material-ui/icons/Menu';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Link } from 'react-router-dom';
import { Routes, RouteShortDefinition } from '../../Routes';
import ekkoLogo from '../ekko.png';
import { ApiStore } from '../Stores/ApiStore';
import { ConfigStore } from '../Stores/ConfigStore';
import { NavigationStore } from '../Stores/NavigationStore';
import { NavigationStyles } from '../Styles/NavigationStyles';

interface NavigationDrawerStores {
  api: ApiStore;
  configStore: ConfigStore;
  navigationStore: NavigationStore;
}

const stores = ['api', 'configStore', 'navigationStore'];

interface NavigationDrawerProps extends WithStyles<typeof NavigationStyles> {}

@inject(...stores)
@observer
class Navigation extends React.Component<NavigationDrawerProps> {
  get stores(): NavigationDrawerStores {
    return this.props as NavigationDrawerProps & NavigationDrawerStores;
  }

  public toggleDrawer = () => {
    const { navigationStore } = this.stores;
    if (navigationStore.open) {
      navigationStore.drawerClose();
    } else {
      navigationStore.drawerOpen();
    }
  };

  public getDrawerOpenState = () => {
    const { navigationStore } = this.stores;
    return navigationStore.open;
  };

  public renderFAQListItem = (faqUrl: string) => {
    const { classes } = this.props;

    const listItem = (
      <ListItem
        button
        style={{
          paddingLeft: 24,
          paddingRight: 24,
        }}
      >
        <HelpOutline className={classes.navigationLinkIcon} />
        <ListItemText
          disableTypography
          className={classes.link}
          primary={<Typography>{'FAQ'}</Typography>}
          style={{ marginLeft: 28 }}
        />
      </ListItem>
    );

    return (
      <a href={faqUrl} className={classes.link} target={'_blank'} rel={'noopener noreferrer'}>
        {this.getDrawerOpenState() && listItem}
        {!this.getDrawerOpenState() && (
          <Tooltip
            title={<span className={classes.drawerTooltipTitle}>FAQ</span>}
            aria-label={'FAQ'}
            placement={'right'}
            enterDelay={150}
            leaveDelay={100}
          >
            {listItem}
          </Tooltip>
        )}
      </a>
    );
  };

  public render() {
    const { api, configStore, navigationStore } = this.stores;
    const { classes } = this.props;
    const { open } = navigationStore;

    const routeFilter = (route: RouteShortDefinition) =>
      route.menu !== undefined &&
      (route.permission === undefined || api.anyNodePermissions.indexOf(route.permission) !== -1);

    const listItems = Routes.filter(routeFilter).map((route) => {
      const filteredRoute = route as Required<RouteShortDefinition>;

      const { link, text, icon: Icon } = filteredRoute.menu;

      const listItem = (
        <ListItem
          button
          style={{
            paddingLeft: 24,
            paddingRight: 24,
          }}
        >
          <Icon className={classes.navigationLinkIcon} />
          <ListItemText
            disableTypography
            className={classes.link}
            primary={<Typography>{text}</Typography>}
            style={{ marginLeft: 28 }}
          />
        </ListItem>
      );

      return (
        <Link className={classes.link} to={link} key={`${text}-${filteredRoute.path}`}>
          {open && listItem}
          {!open && (
            <Tooltip
              title={<span className={classes.drawerTooltipTitle}>{text}</span>}
              aria-label={text}
              placement={'right'}
              enterDelay={150}
              leaveDelay={100}
            >
              {listItem}
            </Tooltip>
          )}
        </Link>
      );
    });

    return (
      <Drawer
        variant="permanent"
        anchor="left"
        classes={{
          paper: classNames(classes.drawerPaper, !this.getDrawerOpenState() && classes.drawerPaperClose),
        }}
        open={this.getDrawerOpenState()}
      >
        <div className={classes.toolbarIconWrapper}>
          <img
            src={ekkoLogo}
            className={classNames(classes.toolbarIconLogo, !this.getDrawerOpenState() && classes.toolbarIconLogoHidden)}
            alt="ekko"
          />
          <IconButton
            onClick={this.toggleDrawer}
            style={{
              marginLeft: 4,
              marginRight: 4,
            }}
          >
            {this.getDrawerOpenState() ? <ChevronLeftIcon /> : <MenuIcon />}
          </IconButton>
        </div>
        <Divider />
        <List>
          {listItems}
          {configStore.config?.faqUrl !== undefined && this.renderFAQListItem(configStore.config?.faqUrl)}
        </List>
      </Drawer>
    );
  }
}

export const NavigationDrawer = withStyles(NavigationStyles)(Navigation);
