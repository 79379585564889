import { TemplateType } from '@ekkogmbh/apisdk';
import {
  Fade,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Select,
  TextField,
  Tooltip,
  Typography,
  withStyles,
  WithStyles,
} from '@material-ui/core';
import { Add, Delete } from '@material-ui/icons';
import { inject, observer } from 'mobx-react';
import { InjectedNotistackProps, withSnackbar } from 'notistack';
import React, { ChangeEvent, MouseEvent } from 'react';
import { LoadingMask } from 'src/Common/Components/LoadingMask';
import { enumKeys } from 'src/Common/Helper/Enum';
import { ConfigStore } from 'src/Common/Stores/ConfigStore';
import { FormStyles } from '../../Common/Styles/FormStyles';
import { TemplateStore } from '../Stores/TemplateStore';

const styles = FormStyles;
const fadeTimeout = 2000;

const stores = ['api', 'templateStore', 'configStore'];

interface TemplateFormStores {
  templateStore: TemplateStore;
  configStore: ConfigStore;
}

interface TemplateFormState {
  loading: boolean;
}

interface TemplateFormProps extends WithStyles<typeof styles>, InjectedNotistackProps {}

@inject(...stores)
@observer
class TemplateFormComponent extends React.Component<TemplateFormProps, TemplateFormState> {
  public state: TemplateFormState = {
    loading: false,
  };

  get stores(): TemplateFormStores {
    return this.props as TemplateFormProps & TemplateFormStores;
  }

  public handleChange = (fieldName: string) => async ({
    target: { value },
  }: ChangeEvent<{ name?: string; value: unknown }>) => {
    const { templateStore } = this.stores;

    switch (fieldName) {
      case 'name':
        templateStore.setState({ name: (value as string).trim() }, true);
        break;
      case 'type':
        templateStore.setState({ type: value as TemplateType }, true);
        break;
      case 'coordinate':
        templateStore.setState({ coordinate: (value as string).trim() }, true);
    }

    templateStore.changed = true;
  };

  public onAddMetadata = (_: MouseEvent<HTMLDivElement>) => {
    const { templateStore } = this.stores;
    const { type, metadata } = templateStore.state;

    switch (type) {
      default:
      case TemplateType.APACHE_FOP:
        const newKeys = Array.from(metadata.keys);
        newKeys.push('');
        templateStore.setState({ metadata: { keys: newKeys } }, true);
        break;
    }

    templateStore.changed = true;
  };

  public onDeleteMetadata = (event: MouseEvent<HTMLDivElement>) => {
    const { templateStore } = this.stores;
    const { type, metadata } = templateStore.state;
    const { fieldName } = event.currentTarget.dataset;

    switch (type) {
      default:
      case TemplateType.APACHE_FOP:
        const newKeys = Array.from(metadata.keys);
        newKeys.splice(parseInt(fieldName!), 1);
        templateStore.setState({ metadata: { keys: newKeys } });
        templateStore.changed = true;
        break;
    }
  };

  public onChangeMetadata = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const { templateStore } = this.stores;
    const { type, metadata } = templateStore.state;

    switch (type) {
      default:
      case TemplateType.APACHE_FOP:
        const newKeys = Array.from(metadata.keys);
        newKeys[parseInt(name)] = value.trim();
        templateStore.setState({ metadata: { keys: newKeys } });
        break;
    }
  };

  public metadataTextField(index: number, value: string): JSX.Element {
    const { classes } = this.props;
    const { templateStore } = this.stores;
    const { rendererResult } = templateStore;
    const { type, metadata } = templateStore.state;

    let helperText: string = '';

    const hasError = (): boolean => {
      switch (type) {
        default:
        case TemplateType.APACHE_FOP:
          const isFilled = value !== '';
          const isUnique = metadata.keys.indexOf(value) === metadata.keys.lastIndexOf(value);
          const isInLayout = Object.keys(rendererResult!.fields).includes(value);

          if (isInLayout || (!isUnique && isFilled)) {
            helperText = 'duplicate';
          }
          return !(isFilled && isUnique) || isInLayout;
      }
    };

    return (
      <Grid item xs={3} key={'metadata-' + index}>
        <TextField
          error={hasError()}
          helperText={helperText}
          className={classes.margin}
          variant="outlined"
          id={`meta-panel-field-${index}`}
          name={index.toString()}
          value={value}
          onChange={this.onChangeMetadata}
          autoFocus={false}
          InputLabelProps={{
            classes: {
              root: classes.label,
              focused: classes.focused,
            },
          }}
          InputProps={{
            classes: {
              root: classes.outlinedInput,
              focused: classes.focused,
              notchedOutline: classes.notchedOutline,
            },
            endAdornment: (
              <InputAdornment variant="filled" position="end">
                <IconButton color="secondary" data-field-name={index} onClick={this.onDeleteMetadata as never}>
                  <Delete />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
    );
  }

  public renderMetadataForm = (): JSX.Element => {
    const { templateStore } = this.stores;
    const { type, metadata } = templateStore.state;

    switch (type) {
      default:
      case TemplateType.APACHE_FOP:
        const keys = metadata.keys;

        return (
          <Grid container spacing={2} alignItems={'stretch'}>
            <Grid item xs={12}>
              <Typography variant="overline" color={'primary'} style={{ fontWeight: 700 }}>
                METADATA
              </Typography>
              <Typography variant="overline" color={'secondary'}>
                Keys
              </Typography>
            </Grid>
            <Grid container spacing={2} alignItems={'stretch'}>
              {keys.length > 0 && keys.map((value: string, index: number) => this.metadataTextField(index, value))}
              <Grid item xs={1}>
                <IconButton color="secondary" onClick={this.onAddMetadata as never}>
                  <Add />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        );
    }
  };

  public render() {
    const { loading } = this.state;
    const { classes } = this.props;
    const { templateStore, configStore } = this.stores;
    const { name, coordinate } = templateStore.state;
    const { rendererResult, reservedField, editableTemplate } = templateStore;
    const { coordinateFieldName } = configStore.config;

    const fields = rendererResult!.fields;
    const keys = Object.keys(fields);
    const templateType = templateStore.state.type;

    const inputLabelProps = {
      classes: {
        root: classes.label,
        focused: classes.focused,
      },
    };

    const inputProps = {
      classes: {
        root: classes.outlinedInput,
        focused: classes.focused,
        notchedOutline: classes.notchedOutline,
        disabled: classes.disabled,
      },
    };

    return (
      <Grid container spacing={2} alignItems={'stretch'}>
        {loading && <LoadingMask />}

        <Grid item xs={12}>
          <Fade in={true} timeout={fadeTimeout}>
            <Grid container spacing={2} alignItems={'stretch'}>
              <Grid item xs={12}>
                <TextField
                  label={'Name'}
                  value={name}
                  name={'name'}
                  onChange={this.handleChange('name')}
                  variant="outlined"
                  className={classes.margin}
                  InputLabelProps={inputLabelProps}
                  InputProps={inputProps}
                  disabled={!!editableTemplate}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  label={'Coordinate'}
                  value={coordinate}
                  name={'coordinate'}
                  onChange={this.handleChange('coordinate')}
                  variant="outlined"
                  className={classes.margin}
                  InputLabelProps={inputLabelProps}
                  InputProps={inputProps}
                  disabled={!!editableTemplate}
                />
              </Grid>

              <Grid item xs={6}>
                <FormControl variant="outlined" className={classes.margin}>
                  <InputLabel htmlFor="outlined-Type-native-simple">Type</InputLabel>
                  <Select
                    native
                    disabled
                    value={templateType}
                    onChange={this.handleChange('type')}
                    input={<OutlinedInput name="Type" labelWidth={34} id="outlined-Type-native-simple" />}
                  >
                    {enumKeys(TemplateType).map((possibleTemplateType) => (
                      <option key={possibleTemplateType} value={TemplateType[possibleTemplateType]}>
                        {possibleTemplateType}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2} alignItems={'stretch'}>
                  <Grid item xs={12}>
                    <Typography variant="overline" color={'primary'} style={{ fontWeight: 700 }}>
                      Layout
                    </Typography>
                    <Typography variant="overline" color={'secondary'}>
                      Keys
                    </Typography>
                  </Grid>
                  {keys.length > 0 &&
                    keys.map((value: string) => (
                      <Grid item xs={'auto'} key={`templatekey-${value}`}>
                        <Typography>{value}</Typography>
                      </Grid>
                    ))}
                  {reservedField && (
                    <Grid item xs={'auto'}>
                      <Tooltip title={'reserved key'}>
                        <Typography color={'textSecondary'}>{coordinateFieldName}</Typography>
                      </Tooltip>
                    </Grid>
                  )}
                </Grid>
              </Grid>

              <Grid item xs={12}>
                {this.renderMetadataForm()}
              </Grid>
            </Grid>
          </Fade>
        </Grid>
      </Grid>
    );
  }
}

const SnackbarWrapped = withSnackbar<TemplateFormProps>(TemplateFormComponent);
const StyleWrapped = withStyles(styles)(SnackbarWrapped);

export const TemplateForm = StyleWrapped;
