import { RendererPayload, RendererResult, SpatialEntityFields, Template } from '@ekkogmbh/apisdk';
import { Collapse, List, ListItem, ListItemText, Theme, Typography, withStyles, WithStyles } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import React from 'react';
import { TemplatePreview } from './TemplatePreview';

const styles = (theme: Theme) => ({
  button: {
    margin: theme.spacing(),
  },
  dialogActions: {
    justifyContent: 'space-between',
  },
});

type TemplateListElement = {
  template: Template;
  doRender: boolean;
  rendering?: RendererResult;
};

interface TemplatePreviewListProps extends WithStyles<typeof styles> {
  templates: Template[];
  fields: SpatialEntityFields;
  fetchRendererResult: (payload: RendererPayload) => Promise<RendererResult>;
  fetchTemplateWithData: (template: Template) => Promise<Template>;
}

interface TemplatePreviewListState {
  loading: boolean;
  templateList: TemplateListElement[];
  fields: SpatialEntityFields;
  updatedBy: string;
}

class TemplatePreviewListComponent extends React.Component<TemplatePreviewListProps, TemplatePreviewListState> {
  public state: TemplatePreviewListState = {
    loading: true,
    templateList: [],
    fields: {} as SpatialEntityFields,
    updatedBy: '',
  };

  private refreshTimeout?: number;
  private templateDataCache: Map<Template['id'], Template['data']> = new Map();

  public resetTimeout() {
    if (this.state.loading) {
      return;
    }

    window.clearTimeout(this.refreshTimeout);
    this.refreshTimeout = window.setTimeout(this.renderTemplates, 500);
  }

  public componentDidMount() {
    const { templates, fields } = this.props;
    const templateList: TemplateListElement[] = [];

    templates.forEach((template: Template) => {
      templateList.push({
        template: template,
        doRender: false,
      } as TemplateListElement);
    });

    // creating a copy of property fields for the state
    const stateFields = JSON.parse(JSON.stringify(fields)) as SpatialEntityFields;
    this.setState({ templateList, fields: stateFields, loading: false, updatedBy: 'init' });
  }

  public componentWillUnmount() {
    window.clearTimeout(this.refreshTimeout);
  }

  public componentDidUpdate(_: TemplatePreviewListProps) {
    if (this.state.updatedBy !== 'render') {
      this.resetTimeout();
    }
  }

  public static getDerivedStateFromProps(props: TemplatePreviewListProps, state: TemplatePreviewListState) {
    const statePatch: Partial<TemplatePreviewListState> = {};

    // detecting templates change
    const currentTemplateIds = state.templateList.map((t: TemplateListElement) => t.template.id);
    const incomingTemplateIds = props.templates.map((t: Template) => t.id);
    const didTemplatesChange = JSON.stringify(currentTemplateIds) !== JSON.stringify(incomingTemplateIds);

    if (didTemplatesChange) {
      const templateList: TemplateListElement[] = [];

      props.templates.forEach((t: Template) => {
        templateList.push({ template: t, doRender: false } as TemplateListElement);
      });

      statePatch.updatedBy = 'props';
      statePatch.templateList = templateList;
    }

    // detecting fields change
    const currentFields = JSON.stringify(state.fields);
    const incomingFields = JSON.stringify(props.fields);
    const didFieldsChange = currentFields !== incomingFields;

    if (didFieldsChange) {
      statePatch.updatedBy = 'props';
      statePatch.fields = JSON.parse(incomingFields) as SpatialEntityFields;
    }

    return statePatch;
  }

  public onClickListButton = (index: number) => {
    const { templateList } = this.state;
    const { doRender } = templateList[index];

    templateList[index].doRender = !doRender;

    this.setState({ templateList, updatedBy: 'button' });
  };

  public renderTemplates = () => {
    const { fetchRendererResult, fetchTemplateWithData } = this.props;
    const { templateList, fields } = this.state;

    this.setState({ loading: true }, async () => {
      for (let index = 0; index < templateList.length; index++) {
        const { template, doRender } = templateList[index];

        if (doRender) {
          if (!this.templateDataCache.has(template.id)) {
            this.templateDataCache.set(template.id, (await fetchTemplateWithData(template)).data);
          }

          const { type } = template;
          const data = this.templateDataCache.get(template.id)!;

          try {
            templateList[index].rendering = await fetchRendererResult({
              data,
              type,
              fields,
              autoFill: false,
              render: true,
            });
          } catch {
            break;
          }
        }
      }
      this.setState({ templateList, loading: false, updatedBy: 'render' });
    });
  };

  public renderListItem(listItem: TemplateListElement, index: number): JSX.Element {
    const { template, doRender, rendering } = listItem;

    const key = template.id + '-preview-list-item';

    return (
      <div key={key}>
        <ListItem button onClick={() => this.onClickListButton(index)}>
          <ListItemText primary={template.name} />
          {doRender ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={doRender} timeout="auto" unmountOnExit>
          {rendering && <TemplatePreview rendererResult={rendering} />}
          {!rendering && <Typography>loading...</Typography>}
        </Collapse>
      </div>
    );
  }

  public render() {
    const { templateList } = this.state;

    return (
      <List>{templateList.map((item: TemplateListElement, index: number) => this.renderListItem(item, index))}</List>
    );
  }
}

export const TemplatePreviewList = withStyles(styles)(TemplatePreviewListComponent);
