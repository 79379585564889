import { FormControl, InputLabel, OutlinedInput, Select, withStyles, WithStyles } from '@material-ui/core';
import { SelectProps } from '@material-ui/core/Select';
import { SelectInputProps } from '@material-ui/core/Select/SelectInput';
import React from 'react';
import { Component } from 'react';
import { FormStyles } from '../../Styles/FormStyles';

const styles = FormStyles;

interface StyledSelectFieldProps extends WithStyles<typeof styles> {
  label: string;
  value: SelectProps['value'];
  onChange: SelectInputProps['onChange'];
  multiple?: boolean;
  disabled?: boolean;
}

class StyledSelectFieldComponent extends Component<StyledSelectFieldProps> {
  public render() {
    const { classes, label, value, disabled, multiple, onChange, children } = this.props;

    return (
      <FormControl variant="outlined" className={classes.margin}>
        <InputLabel htmlFor={'outlined-' + label}>{label}</InputLabel>
        <Select
          value={value}
          onChange={onChange}
          multiple={multiple}
          disabled={disabled}
          input={<OutlinedInput id={'outlined-' + label} name={label} labelWidth={label.length * 8} />}
        >
          {children}
        </Select>
      </FormControl>
    );
  }
}

const StyleWrapped = withStyles(styles)(StyledSelectFieldComponent);

export const StyledSelectField = StyleWrapped;
