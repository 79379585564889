import { ApiSdkEvents, Endpoint, EslManagerPrivateRoute, EslManagerPublicRouteV1, HttpMethod } from '@ekkogmbh/apisdk';
import { ApiStore } from '../Stores/ApiStore';
import { CancelableFetchPromises, createCancelableFetchPromise } from './PromiseHelper';
import { EnqueueSnackbarFn, handleError, handleSuccess, SuccessHandlerStatusMessages } from './ResponseHandler';

export type RequestWrapper<T> = (
  endpoint: Endpoint,
  method: HttpMethod,
  successStatusCodes?: SuccessHandlerStatusMessages,
  errorStatusCodes?: number[],
) => Promise<T>;

const noopHandler = (): void => {
  //
};

export const request = async <T>(
  api: ApiStore,
  enqueueSnackbar: EnqueueSnackbarFn,
  fetchPromises: CancelableFetchPromises,
  apiPromise: Promise<T>,
  route: EslManagerPrivateRoute | EslManagerPublicRouteV1,
  method: HttpMethod,
  successStatusCodes?: SuccessHandlerStatusMessages,
  successCallback?: () => void,
  errorCallback?: () => void,
): Promise<T> => {
  const endpoint: Endpoint = { path: route };

  const requestWrapper = createRequestWrapper<T>(api, apiPromise, enqueueSnackbar, successCallback, errorCallback);

  const { promise } = createCancelableFetchPromise<T>(
    fetchPromises,
    route,
    method,
    requestWrapper(endpoint, method, successStatusCodes),
  );

  return await promise;
};

export function createRequestWrapper<T>(
  api: ApiStore,
  promise: Promise<T>,
  enqueueSnackbar?: EnqueueSnackbarFn,
  successCallback?: () => void,
  errorCallback?: () => void,
): RequestWrapper<T> {
  return async (
    endpoint: Endpoint,
    method: HttpMethod,
    successStatusCodes?: SuccessHandlerStatusMessages,
    errorStatusCodes?: number[],
  ): Promise<T> => {
    const eventError = api.endpointEventTypeWithMethod(ApiSdkEvents.REQUEST_ERROR, endpoint, method);
    const eventSuccess = api.endpointEventTypeWithMethod(ApiSdkEvents.REQUEST_FINISHED, endpoint, method);
    const errorHandler =
      enqueueSnackbar === undefined ? noopHandler : handleError(enqueueSnackbar, errorStatusCodes, errorCallback);
    const successHandler =
      enqueueSnackbar === undefined ? noopHandler : handleSuccess(enqueueSnackbar, successStatusCodes, successCallback);

    api.once(eventError, errorHandler);
    api.once(eventSuccess, successHandler);

    const removeListener = () => {
      api.off(eventError, errorHandler);
      api.off(eventSuccess, successHandler);
    };

    promise.then(removeListener).catch(removeListener);

    return await promise;
  };
}
