import {
  CompartmentSelectionStrategy,
  EslManagerPrivateRoute,
  HttpMethod,
  LinkProfile,
  SaveCompartmentSelectorPayload,
  Technology,
  TemplateGroups,
} from '@ekkogmbh/apisdk';
import {
  Fade,
  FormControl,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  OutlinedInput,
  Select,
  Typography,
  withStyles,
  WithStyles,
} from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import { InjectedNotistackProps, withSnackbar } from 'notistack';
import React from 'react';
import { CheckmarkSpinner } from 'src/Common/Components/CheckmarkSpinner';
import { FormPanelButtons } from 'src/Common/Components/FormPanelButtons';
import { spacer } from 'src/Common/Components/Forms/Spacer';
import { StyledTextField } from 'src/Common/Components/Forms/StyledTextField';
import { DynamicStepper, DynamicStepperStep } from 'src/Common/Components/Stepper/DynamicStepper';
import { request } from 'src/Common/Helper/FetchHandler';
import { CancelableFetchPromises, noop } from 'src/Common/Helper/PromiseHelper';
import { ApiStore } from 'src/Common/Stores/ApiStore';
import { FormStyles } from 'src/Common/Styles/FormStyles';
import { LabelLinkManager } from 'src/LabelManagement/Components/LabelLinkManager';
import { LabelLinkStore } from 'src/LabelManagement/Stores/LabelLinkStore';
import { LinkProfileStore } from '../Stores/LinkProfileStore';

const styles = FormStyles;

interface LinkProfilePanelStores {
  api: ApiStore;
  linkProfileStore: LinkProfileStore;
  labelLinkStore: LabelLinkStore;
}

interface LinkProfilePanelState {
  availableTechnologies: string[];
  activeStep: number;
  finished: boolean;
  loading: boolean;
}

export interface LinkProfilePanelProps extends InjectedNotistackProps, WithStyles<typeof styles> {
  closeHandler: () => void;
  saveHandler: (linkProfile: LinkProfile) => Promise<LinkProfile>;
}

@inject('api', 'linkProfileStore', 'labelLinkStore')
@observer
class LinkProfilePanelComponent extends React.Component<LinkProfilePanelProps, LinkProfilePanelState> {
  public state: LinkProfilePanelState = {
    availableTechnologies: [],
    activeStep: 0,
    finished: false,
    loading: false,
  };
  private fetchPromises: CancelableFetchPromises = {};

  get stores(): LinkProfilePanelStores {
    return this.props as LinkProfilePanelProps & LinkProfilePanelStores;
  }

  public async componentDidMount(): Promise<void> {
    const { linkProfileStore, labelLinkStore } = this.stores;
    const { editableLinkProfile } = linkProfileStore;

    if (editableLinkProfile) {
      labelLinkStore.initialTemplateGroups = editableLinkProfile.templateGroups;
      labelLinkStore.resetTemplateGroups();
    } else {
      labelLinkStore.resetStore();
    }

    const availableTechnologies = await this.fetchAllTechnologies();
    this.setState({ availableTechnologies }, () => {
      linkProfileStore.setState({ technology: availableTechnologies[0] });
    });
  }

  public handleReset = async () => {
    this.componentDidMount();
    this.setState({ activeStep: 0, loading: false });
  };

  public componentWillUnmount(): void {
    const { linkProfileStore } = this.stores;
    linkProfileStore.resetStore();
    this.componentDidMount();
  }

  public handleSave = async () => {
    const { saveHandler, closeHandler } = this.props;
    const { linkProfileStore, labelLinkStore } = this.stores;

    const compartmentSelector: SaveCompartmentSelectorPayload = {
      type: linkProfileStore.state.compartmentSelector,
      configuration: linkProfileStore.state.compartmentSelectorConfiguration,
    };

    const templateGroups: TemplateGroups = labelLinkStore.templateGroups;

    const autoTemplateGroup = linkProfileStore.state.autoTemplateGroup;

    const payload: LinkProfile = {
      name: linkProfileStore.state.name,
      technology: linkProfileStore.state.technology,
      coordinate: linkProfileStore.state.coordinate,
      templateGroups,
      compartmentSelector,
      autoTemplateGroup: !!autoTemplateGroup === false ? null : autoTemplateGroup,
    };

    this.setState(
      {
        loading: true,
      },
      async () => {
        try {
          await saveHandler(payload);
          this.handleReset();
          closeHandler();
        } catch (e) {
          this.setState({ loading: false });
        }
      },
    );
  };

  public isNextDisabled = (step: DynamicStepperStep): boolean => {
    const { linkProfileStore, labelLinkStore } = this.stores;

    switch (step.title) {
      case 'Profile':
        const { name, coordinate, technology } = linkProfileStore.state;
        return name === '' || coordinate === '' || technology === '';
      case 'Template Groups':
        return !labelLinkStore.hasChanged() && linkProfileStore.editableLinkProfile === undefined;
      case 'Overview':
        return true;
    }

    return false;
  };

  public handleNext = async () => {
    const { activeStep } = this.state;

    this.setState({ activeStep: activeStep + 1 });
  };

  public handleBack = async () => {
    const { activeStep } = this.state;

    this.setState({ activeStep: activeStep - 1 });
  };

  public getSteps = (): DynamicStepperStep[] => [
    {
      title: 'Profile',
      elementCallback: (): JSX.Element => {
        const { classes } = this.props;
        const { linkProfileStore } = this.stores;
        const { availableTechnologies } = this.state;
        const { name, coordinate, technology } = linkProfileStore.state;

        const technologyOptions = availableTechnologies.map((technologyName: string, index: number) => (
          <option key={index} value={technologyName}>
            {technologyName}
          </option>
        ));

        const isDisabled = linkProfileStore.editableLinkProfile !== undefined;

        return (
          <Grid container item xs={3} spacing={2} alignContent={'stretch'}>
            <Grid item xs={12}>
              <StyledTextField
                disabled={isDisabled}
                type={'text'}
                label={'Name'}
                value={name}
                onChange={(e) => linkProfileStore.setState({ name: e.target.value })}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledTextField
                disabled={isDisabled}
                type={'text'}
                label={'Coordinate'}
                value={coordinate}
                onChange={(e) => linkProfileStore.setState({ coordinate: e.target.value })}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="outlined" className={classes.margin}>
                <InputLabel
                  htmlFor="outlined-technology-simple"
                  classes={{ root: classes.lable, focused: classes.focused }}
                >
                  Technology
                </InputLabel>
                <Select
                  native
                  value={technology}
                  onChange={(e) => linkProfileStore.setState({ technology: e.target.value as string })}
                  input={
                    <OutlinedInput
                      name="technology"
                      labelWidth={84}
                      id="outlined-technology-simple"
                      classes={{
                        root: classes.outlinedInput,
                        focused: classes.focused,
                        notchedOutline: classes.notechOutline,
                        disabled: classes.disabled,
                      }}
                    />
                  }
                >
                  {technologyOptions}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        );
      },
    },
    {
      title: 'Template Groups',
      elementCallback: (): JSX.Element => {
        const { linkProfileStore } = this.stores;
        const { coordinate } = linkProfileStore.state;

        return (
          <Fade in={true} timeout={1000}>
            <Grid item xs={4}>
              {coordinate && (
                <LabelLinkManager
                  type={'profile'}
                  coordinate={coordinate}
                  errorHandler={() => this.setState({ activeStep: 0 })}
                />
              )}
            </Grid>
          </Fade>
        );
      },
    },
    {
      title: 'Configuration',
      elementCallback: (): JSX.Element => {
        const { classes } = this.props;
        const { linkProfileStore, labelLinkStore } = this.stores;
        const { compartmentSelector, autoTemplateGroup } = linkProfileStore.state;

        const strategies = Object.entries(CompartmentSelectionStrategy);
        const strategyOptions = strategies.map(([key, value]) => (
          <option key={`selector-strategy-${key}`}>{value.toString()}</option>
        ));

        const templateGroups = labelLinkStore.getGroupNames();
        const templateGroupOptions = templateGroups.map((groupName: string, index: number) => (
          <option key={`auto-template-option-${index}`}>{groupName}</option>
        ));

        return (
          <Fade in={true} timeout={1000}>
            <Grid container item xs={3} alignItems={'stretch'}>
              <Grid item xs={12}>
                <FormControl variant="outlined" className={classes.margin}>
                  <InputLabel htmlFor="outlined-template-simple">Automatic Template Group</InputLabel>
                  <Select
                    native
                    value={autoTemplateGroup}
                    onChange={(e) => linkProfileStore.setState({ autoTemplateGroup: e.target.value as string })}
                    input={<OutlinedInput name="autoTemplateGroup" labelWidth={195} id="outlined-template-simple" />}
                  >
                    <option value={undefined}>{''}</option>
                    {templateGroupOptions}
                  </Select>
                </FormControl>
              </Grid>
              {spacer(12)}
              <Grid item xs={12}>
                <FormControl variant="outlined" className={classes.margin}>
                  <InputLabel htmlFor="outlined-selector-strategy">Compartment-Selection-Strategy</InputLabel>
                  <Select
                    native
                    value={compartmentSelector}
                    onChange={(e) =>
                      linkProfileStore.setState({ compartmentSelector: e.target.value as CompartmentSelectionStrategy })
                    }
                    input={<OutlinedInput name="selectorStrategy" labelWidth={238} id="outlined-selector-strategy" />}
                  >
                    {strategyOptions}
                  </Select>
                </FormControl>
              </Grid>
              <Grid container item xs={12} spacing={0} alignItems={'stretch'}>
                <Fade in={true}>{this.getCompartmentStrategyForm()}</Fade>
              </Grid>
            </Grid>
          </Fade>
        );
      },
    },
    {
      title: 'Overview',
      elementCallback: (): JSX.Element => {
        const { linkProfileStore, labelLinkStore } = this.stores;
        const {
          name,
          coordinate,
          compartmentSelector,
          compartmentSelectorConfiguration,
          autoTemplateGroup,
        } = linkProfileStore.state;

        const overlineStyle = { fontWeight: 700 };
        const templateGroups = labelLinkStore.getGroupNames();

        return (
          <Grid container spacing={2} alignItems={'stretch'}>
            <Grid item xs={6}>
              <Typography variant="overline" color={'primary'} style={overlineStyle}>
                Name
              </Typography>
              <Typography variant="h6" gutterBottom>
                {name}
              </Typography>
              <Typography variant="overline" color={'primary'} style={overlineStyle}>
                Coordinate
              </Typography>
              <Typography variant="h6" gutterBottom>
                {coordinate}
              </Typography>
              {autoTemplateGroup && (
                <>
                  <Typography variant="overline" color={'primary'} style={overlineStyle}>
                    Automatic Template Group
                  </Typography>
                  <Typography variant="h6" gutterBottom>
                    {autoTemplateGroup}
                  </Typography>
                </>
              )}
              <Typography variant="overline" color={'primary'} style={overlineStyle}>
                Compartment Selector
              </Typography>
              <Typography variant="h6" gutterBottom>
                {compartmentSelector}
              </Typography>
              {Object.keys(compartmentSelectorConfiguration).map((key: string) => (
                <div key={`selector-config-key-${key}`}>
                  <Typography>
                    {key}: {compartmentSelectorConfiguration[key]}
                  </Typography>
                </div>
              ))}
            </Grid>
            <Grid item xs={6}>
              <Typography variant="overline" color={'primary'} style={overlineStyle}>
                Template Groups
              </Typography>
              <List>
                {templateGroups.map((name: string) => {
                  const pages = labelLinkStore.getPageIndices(name);
                  return (
                    <div key={`overview-template-group-${name}`}>
                      <ListItem>
                        <ListItemText>
                          <Typography variant={'h6'}>{name}</Typography>
                        </ListItemText>
                      </ListItem>
                      {pages.map((page: string) => {
                        const templateName = labelLinkStore.templateGroups[name][page];
                        return (
                          <ListItem key={`overview-page-${name}-${page}`}>
                            <ListItemText>
                              <Typography variant={'body1'}>
                                {page} - {templateName}
                              </Typography>
                            </ListItemText>
                          </ListItem>
                        );
                      })}
                    </div>
                  );
                })}
              </List>
            </Grid>
          </Grid>
        );
      },
    },
  ];

  public getCompartmentStrategyForm(): JSX.Element {
    const { linkProfileStore } = this.stores;
    const { compartmentSelector, compartmentSelectorConfiguration, coordinate } = linkProfileStore.state;

    switch (compartmentSelector) {
      case CompartmentSelectionStrategy.VALUE:
        return (
          <Grid item xs={12}>
            <StyledTextField
              type={'text'}
              label={'Compartment Field Key'}
              value={compartmentSelectorConfiguration.key ?? ''}
              onChange={(e) => linkProfileStore.setState({ compartmentSelectorConfiguration: { key: e.target.value } })}
            />
          </Grid>
        );
      case CompartmentSelectionStrategy.COORDINATE:
        return (
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <StyledTextField type={'text'} label={'Prefix'} value={coordinate} disabled={true} onChange={noop} />
            </Grid>
            <Grid item xs={6}>
              <StyledTextField
                type={'text'}
                label={'Infix (optional)'}
                value={compartmentSelectorConfiguration.infix ?? ''}
                onChange={(e) =>
                  linkProfileStore.setState({
                    compartmentSelectorConfiguration: e.target.value == '' ? {} : { infix: e.target.value },
                  })
                }
              />
            </Grid>
          </Grid>
        );
      default:
        return <Typography>Select a Compartment Selector Strategy</Typography>;
    }
  }

  public fetchAllTechnologies = async (): Promise<string[]> => {
    const { api } = this.stores;
    const { enqueueSnackbar } = this.props;

    const technologies = await request<Technology[]>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.getTechnologies(),
      EslManagerPrivateRoute.TECHNOLOGIES,
      HttpMethod.GET,
    );

    return technologies.map((technology) => technology.name);
  };

  public render() {
    const { closeHandler } = this.props;
    const { activeStep, finished, loading } = this.state;
    const steps = this.getSteps();

    const finalStep = steps.length - 1;
    const isNextDisabled = this.isNextDisabled(steps[activeStep]);

    return (
      <Grid container spacing={2} alignContent={'stretch'}>
        <Grid item xs={12}>
          <div style={{ display: loading ? 'block' : 'none' }}>
            <Grid container spacing={2} alignItems={'stretch'}>
              <Grid
                item
                xs={12}
                style={{
                  height: 496,
                  position: 'relative',
                }}
              >
                <div
                  style={{
                    top: '50%',
                    marginTop: -48,
                    position: 'absolute',
                    width: '100%',
                  }}
                >
                  <CheckmarkSpinner complete={finished} failure={false} />
                </div>
              </Grid>
            </Grid>
          </div>

          {!loading && <DynamicStepper activeStep={activeStep} steps={steps} />}
        </Grid>
        <Grid item xs={12}>
          <FormPanelButtons
            cancelHandler={closeHandler}
            finished={finished}
            resetHandler={this.handleReset}
            backHandler={this.handleBack}
            nextHandler={this.handleNext}
            saveHandler={this.handleSave}
            isSaveDisabled={activeStep !== finalStep}
            isBackDisabled={activeStep === 0}
            isNextDisabled={isNextDisabled}
            isDeleteDisabled={true}
            isDeleteHidden={true}
          />
        </Grid>
      </Grid>
    );
  }
}

const SnackbarWrapped = withSnackbar<LinkProfilePanelProps>(LinkProfilePanelComponent);
const StyleWrapped = withStyles(styles)(SnackbarWrapped);

export const LinkProfilePanel = StyleWrapped;
