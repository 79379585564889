import { Template, TemplateType, TemplateWithData } from '@ekkogmbh/apisdk';
import { Typography } from '@material-ui/core';
import { Delete, Edit, GetApp, Image } from '@material-ui/icons';
import { MaterialDatatableColumnDef } from 'material-datatable';
import React from 'react';
import { DatatableColumnDefinitionFn, TableMeta } from 'src/Common/Components/DataTable';
import { ActionButton, DataTableActionsComponent } from 'src/Common/Components/DataTable/DataTableActions';
import { Permissions } from 'src/Common/Stores/ApiStore';
import { DataTableRecord } from 'src/Common/Stores/DataTableStore';
import {
  TemplateManagementContentActionHandlers,
  TemplateManagementContentPropsWithStores,
  TemplateManagementContentState,
} from './TemplateManagementContent';

class TemplateManagementDataTableActions extends DataTableActionsComponent<DataTableRecord<TemplateWithData>> {}

const TemplateNameColumn = (): MaterialDatatableColumnDef => ({
  name: 'Name',
  field: 'name',
  options: {
    sort: true,
    filter: false,
    headerNoWrap: true,
    // @TODO ?
    // eslint-disable-next-line react/display-name
    customBodyRender: (value: Template): React.ReactNode => <div style={{ fontWeight: 700 }}>{value.name}</div>,
  },
});

const TemplateCoordinateColumn = (): MaterialDatatableColumnDef => ({
  name: 'Coordinate',
  field: 'coordinate',
  options: {
    sort: false,
    filter: false,
    headerNoWrap: true,
    customBodyRender: (value: Template): React.ReactNode => <div style={{ fontWeight: 700 }}>{value.coordinate}</div>,
  },
});

const TemplateTypeColumn = (): MaterialDatatableColumnDef => ({
  name: 'Type',
  field: 'type',
  options: {
    sort: false,
    filter: false,
    headerNoWrap: true,
    // @TODO ?
    // eslint-disable-next-line react/display-name
    customBodyRender: (value: Template): React.ReactNode => <div>{value.type}</div>,
  },
});

const TemplateMetadataColumn = (): MaterialDatatableColumnDef => ({
  name: 'Metadata',
  field: 'metadata',
  options: {
    sort: false,
    filter: false,
    headerNoWrap: true,
    // @TODO ?
    // eslint-disable-next-line react/display-name
    customBodyRender: (value: Template): React.ReactNode => {
      const noneComponent = (
        <Typography color={'textSecondary'} style={{ fontWeight: 700 }}>
          ―
        </Typography>
      );

      switch (value.type) {
        case TemplateType.APACHE_FOP:
        case TemplateType.SVG_TERA:
          return value.metadata.keys.length > 0 ? (
            <div>
              <Typography display={'inline'} color={'textSecondary'} style={{ fontWeight: 700 }}>
                keys:{' '}
              </Typography>
              <Typography display={'inline'} color={'textPrimary'}>
                {value.metadata.keys.join(', ')}
              </Typography>
            </div>
          ) : (
            noneComponent
          );
        default:
          return noneComponent;
      }
    },
  },
});

const TemplateActionsColumn = (
  _: TemplateManagementContentState,
  propsWithStores: TemplateManagementContentPropsWithStores,
  actions: TemplateManagementContentActionHandlers,
): MaterialDatatableColumnDef => ({
  name: '',
  field: '',
  options: {
    width: 48 * 4,
    sort: false,
    filter: false,
    headerNoWrap: true,
    customBodyRender: (template: TemplateWithData, tableMeta: TableMeta): React.ReactNode => {
      const nodeValues = template.coordinate.split(/[._—/]+/);
      const hasWritePermission = propsWithStores.api.userHasPermissionForNode(Permissions.TEMPLATES_WRITE, nodeValues);

      const actionButtons: ActionButton<TemplateWithData>[] = [
        {
          title: 'Preview',
          onClick: actions.preview,
          icon: Image,
          disabled: false,
        },
        {
          title: 'Download',
          onClick: actions.download,
          icon: GetApp,
          disabled: false,
        },
        {
          title: 'Edit',
          onClick: actions.edit,
          icon: Edit,
          disabled: !hasWritePermission,
        },
        {
          title: 'Delete',
          onClick: actions.delete,
          icon: Delete,
          disabled: !hasWritePermission,
        },
      ];

      return (
        <TemplateManagementDataTableActions
          dataset={template}
          width={tableMeta.columnData.width}
          isProcessing={false}
          actionButtons={actionButtons}
        />
      );
    },
  },
});

export const materialDatatableColumnDefinitions: Array<DatatableColumnDefinitionFn<
  TemplateManagementContentPropsWithStores,
  TemplateManagementContentState,
  TemplateManagementContentActionHandlers
>> = [TemplateNameColumn, TemplateCoordinateColumn, TemplateTypeColumn, TemplateMetadataColumn, TemplateActionsColumn];
