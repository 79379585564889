import { EslManagerPrivateRoute, EslManagerPublicRouteV1, HttpMethod } from '@ekkogmbh/apisdk';

export const sleep = async (ms: number): Promise<void> => new Promise((resolve) => setTimeout(resolve, ms));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface CancelablePromise<T = any> {
  promise: Promise<T>;
  cancel: () => void;
  isResolved: () => boolean;
}

export interface CancelableFetchPromises {
  [key: string]: CancelablePromise;
}

export const promiseKeys = {
  ...EslManagerPrivateRoute,
  ...EslManagerPublicRouteV1,
  general: 'general',
};

export const noop = (): void => undefined;
export const noopAsync = async (): Promise<void> => undefined;

export const cancelFetchPromises = (fetchPromises: CancelableFetchPromises): void => {
  Object.keys(fetchPromises).forEach((key: string) => {
    if (fetchPromises[key] && !fetchPromises[key].isResolved()) {
      fetchPromises[key].cancel();
    }
  });
};

export const makePromiseCancelable = <T>(promise: Promise<T>): CancelablePromise<T> => {
  let hasCanceled = false;
  let isResolved = false;

  const wrappedPromise: Promise<T> = new Promise((resolve, reject) => {
    promise.then(
      (val) => {
        isResolved = true;
        hasCanceled ? reject({ isCanceled: true }) : resolve(val);
      },
      (error) => {
        isResolved = true;
        hasCanceled ? reject({ isCanceled: true }) : reject(error);
      },
    );
  });

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled = true;
    },
    isResolved() {
      return isResolved;
    },
  };
};

export const createCancelableFetchPromise = <T>(
  fetchPromises: CancelableFetchPromises,
  eslManagerRoute: EslManagerPrivateRoute | EslManagerPublicRouteV1,
  eslManagerRouteMethod: HttpMethod,
  promise: Promise<T>,
): CancelablePromise<T> => {
  const index = eslManagerRouteMethod + eslManagerRoute;
  if (fetchPromises[index] && !fetchPromises[index].isResolved()) {
    fetchPromises[index].cancel();
  }

  fetchPromises[index] = makePromiseCancelable<T>(promise);

  return fetchPromises[index];
};
